const dutchLocales = ['nl-NL', 'nl-BE', 'nl'];

const getLanguage = () => {
  const localStorageLanguage = localStorage.getItem('language');

  if (dutchLocales.includes(localStorageLanguage ?? navigator.language)) {
    return 'nl';
  }

  return 'en';
};

export default getLanguage;
