import React from 'react';
import { Helmet } from 'react-helmet';
import PushToTop from '../../components/PushToTop';
import Container from '../../components/Container';
import { injectIntl, intlShape, FormattedMessage } from 'react-intl';

const Privacy = ({ intl }) => (
  <PushToTop>
    <Helmet>
      <title>{intl.formatMessage({ id: 'pages.privacy.page_title' })}</title>
    </Helmet>
    <Container>
      <h1>
        <FormattedMessage id="pages.privacy.title" />
      </h1>
      <p>
        <FormattedMessage id="pages.privacy.text" />
      </p>
      <h4>
        <FormattedMessage id="pages.privacy.documents.dutch" />
      </h4>
      <ul>
        <li>
          <a
            href="/_privacy/docs/privacyverklaring-egodact-software.pdf"
            target="_blank"
          >
            Privacyverklaring Egodact software
          </a>
        </li>
        <li>
          <a
            href="/_privacy/docs/verwerkersovereenkomst-egodact-software.pdf"
            target="_blank"
          >
            Verwerkersovereenkomst Egodact software
          </a>
        </li>
        <li>
          <a
            href="/_privacy/docs/dpia-egodact-software-dutch.pdf"
            target="_blank"
          >
            DPIA Egodact software
          </a>
        </li>
      </ul>
      <h4>
        <FormattedMessage id="pages.privacy.documents.english" />
      </h4>
      <ul>
        <li>
          <a
            href="/_privacy/docs/privacy-statement-egodact-software.pdf"
            target="_blank"
          >
            Privacy statement Egodact software
          </a>
        </li>
        <li>
          <a
            href="/_privacy/docs/data-processing-agreement-egodact-software.pdf"
            target="_blank"
          >
            Data processing agreement Egodact software
          </a>
        </li>
        <li>
          <a href="/_privacy/docs/dpia-egodact-software.pdf" target="_blank">
            DPIA Egodact software
          </a>
        </li>
      </ul>
    </Container>
  </PushToTop>
);

Privacy.propTypes = {
  intl: intlShape.isRequired
};

export default injectIntl(Privacy);
