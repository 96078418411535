import React from 'react';
import { Helmet } from 'react-helmet';
import PushToTop from '../../components/PushToTop';
import Container from '../../components/Container';
import { injectIntl } from 'react-intl';
import getLanguage from '../../getLanguage';

const ResponsibleDisclosure = () => {
  const language = getLanguage();

  return (
    <PushToTop>
      <Helmet>
        <title>Coordinated Vulnerability Disclosure</title>
      </Helmet>
      <Container>
        <h1>Coordinated Vulnerability Disclosure</h1>
        {language === 'nl' ? (
          <>
            <p>
              Bij Egodact vinden wij de veiligheid van onze systemen erg
              belangrĳk. Ondanks onze zorg voor de beveiliging van onze systemen
              kan het voorkomen dat er toch een zwakke plek is.
            </p>
            <p>
              Als u een zwakke plek in één van onze systemen heeft gevonden
              horen wij dit graag zodat we zo snel mogelĳk maatregelen kunnen
              treffen. Wij willen graag met u samenwerken om onze klanten en
              onze systemen beter te kunnen beschermen.
            </p>
            <h3>Wij vragen u:</h3>
            <ul>
              <li>
                Uw bevindingen te mailen naar{' '}
                <a href="mailto:contact@egodact.com">contact@egodact.com</a>.
                Versleutel uw bevindingen met onze{' '}
                <a href="/pgp.txt">PGP key</a> om te voorkomen dat de informatie
                in verkeerde handen valt,
              </li>
              <li>
                Het probleem niet te misbruiken door bijvoorbeeld meer data te
                downloaden dan nodig is om het lek aan te tonen of gegevens van
                derden in te kijken, verwijderen of aanpassen,
              </li>
              <li>
                Het probleem niet met anderen te delen totdat het is opgelost en
                alle vertrouwelijke gegevens die zijn verkregen via het lek
                direct na het dichten van het lek te wissen,
              </li>
              <li>
                Geen gebruik te maken van aanvallen op fysieke beveiliging,
                social engineering, distributed denial of service, spam of
                applicaties van derden, en
              </li>
              <li>
                Voldoende informatie te geven om het probleem te reproduceren
                zodat wij het zo snel mogelijk kunnen oplossen. Meestal is het
                IP-adres of de URL van het getroffen systeem en een omschrijving
                van de kwetsbaarheid voldoende, maar bij complexere
                kwetsbaarheden kan meer nodig zijn.
              </li>
            </ul>
            <h3>Wat wij beloven:</h3>
            <ul>
              <li>
                Wij reageren binnen 3 werkdagen op uw melding met onze
                beoordeling van de melding en een verwachte datum voor een
                oplossing,
              </li>
              <li>
                Als u zich aan bovenstaande voorwaarden heeft gehouden zullen
                wij geen juridische stappen tegen u ondernemen betreffende de
                melding,
              </li>
              <li>
                Wij behandelen uw melding vertrouwelijk en zullen uw
                persoonlijke gegevens niet zonder uw toestemming met derden
                delen tenzij dat noodzakelijk is om een wettelijke verplichting
                na te komen. Melden onder een pseudoniem is mogelijk,
              </li>
              <li>
                Wij houden u op de hoogte van de voortgang van het oplossen van
                het probleem,
              </li>
              <li>
                In berichtgeving over het gemelde probleem zullen wij, indien u
                dit wenst, uw naam vermelden als de ontdekker, en
              </li>
              <li>
                Als dank voor uw hulp bieden wij een beloning aan voor elke
                melding van een ons nog onbekend beveiligingsprobleem. De
                grootte van de beloning bepalen wij aan de hand van de ernst van
                het lek en de kwaliteit van de melding.
              </li>
            </ul>
            <p>
              Wij streven er naar om alle problemen zo snel mogelijk op te
              lossen en wij worden graag betrokken bij een eventuele publicatie
              over het probleem nadat het is opgelost.
            </p>
            <p style={{ fontStyle: 'italic' }}>
              Versie 1, gepubliceerd op 2 november 2023
            </p>
            <br />
            <p>
              Dank aan Floor Terra voor zijn voorbeeldtekst op{' '}
              <a
                href="https://responsibledisclosure.nl"
                target="_blank"
                rel="noopener noreferrer"
              >
                responsibledisclosure.nl
              </a>
              . We hebben enkele kleine wijzigingen toegepast.
            </p>
          </>
        ) : (
          <>
            <p>
              At Egodact, we consider the security of our systems a top
              priority. But no matter how much effort we put into system
              security, there can still be vulnerabilities present.
            </p>
            <p>
              If you discover a vulnerability, we would like to know about it so
              we can take steps to address it as quickly as possible. We would
              like to ask you to help us better protect our clients and our
              systems.
            </p>
            <h3>Please do the following:</h3>
            <ul>
              <li>
                E-mail your findings to{' '}
                <a href="mailto:contact@egodact.com">contact@egodact.com</a>.
                Encrypt your findings using our <a href="/pgp.txt">PGP key</a>{' '}
                to prevent this critical information from falling into the wrong
                hands,
              </li>
              <li>
                Do not take advantage of the vulnerability or problem you have
                discovered, for example by downloading more data than necessary
                to demonstrate the vulnerability or deleting or modifying other
                people's data,
              </li>
              <li>
                Do not reveal the problem to others until it has been resolved,
              </li>
              <li>
                Do not use attacks on physical security, social engineering,
                distributed denial of service, spam or applications of third
                parties, and
              </li>
              <li>
                Do provide sufficient information to reproduce the problem, so
                we will be able to resolve it as quickly as possible. Usually,
                the IP address or the URL of the affected system and a
                description of the vulnerability will be sufficient, but complex
                vulnerabilities may require further explanation.
              </li>
            </ul>
            <h3>What we promise:</h3>
            <ul>
              <li>
                We will respond to your report within 3 business days with our
                evaluation of the report and an expected resolution date,
              </li>
              <li>
                If you have followed the instructions above, we will not take
                any legal action against you in regard to the report,
              </li>
              <li>
                We will handle your report with strict confidentiality, and not
                pass on your personal details to third parties without your
                permission,
              </li>
              <li>
                We will keep you informed of the progress towards resolving the
                problem,
              </li>
              <li>
                In the public information concerning the problem reported, we
                will give your name as the discoverer of the problem (unless you
                desire otherwise), and
              </li>
              <li>
                As a token of our gratitude for your assistance, we offer a
                reward for every report of a security problem that was not yet
                known to us. The amount of the reward will be determined based
                on the severity of the leak and the quality of the report.
              </li>
            </ul>
            <p>
              We strive to resolve all problems as quickly as possible, and we
              would like to play an active role in the ultimate publication on
              the problem after it is resolved.
            </p>
            <p style={{ fontStyle: 'italic' }}>
              Version 1.0, published November 2, 2023
            </p>
            <br />
            <p>
              Thanks to Floor Terra for his example text at{' '}
              <a
                href="https://responsibledisclosure.nl/en"
                target="_blank"
                rel="noopener noreferrer"
              >
                responsibledisclosure.nl
              </a>
              . We made some minor adjustments.
            </p>
          </>
        )}
      </Container>
    </PushToTop>
  );
};

export default injectIntl(ResponsibleDisclosure);
